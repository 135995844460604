setTimeout(function(){
    $(function () {
        if ($('.meetingInfo').length) {
            var meetingName = $.trim($('.meetingInfo').parent().find('h1').text());
            var meetingDate = $.trim($('.meetingInfo #meetingDate + .waarde').text());
            var meetingLocation = $.trim($('.meetingInfo #meetingLocation + .waarde').text());
            var meetingPlace = $.trim($('.meetingInfo #meetingAddress + .waarde .subveld').text());
            var meetingPlaceLength = meetingPlace.length;

            meetingPlace = meetingPlace.substring(8, meetingPlaceLength);

            meetingPlace = $.trim(meetingPlace);
            meetingPlace = meetingLocation + ' te ' + meetingPlace;

            $('#LIVITS_ID_4606077621579').val(meetingName);
            $('#LIVITS_ID_8906310636380').val(meetingDate);
            $('#LIVITS_ID_9261420329146').val(meetingPlace);
        }
    });
}, 1500);

$(document).ajaxStop(function() {
    var h3Headings = document.getElementsByTagName("h3");
    for (var i = 0; i < h3Headings.length; i++) {
        if (h3Headings[i].innerText === "Collega's") {
            h3Headings[i].parentElement.remove();
        }
        if (document.querySelector("meeting-navigation")) {
            document.querySelector("meeting-navigation").style.display = "block";
        }
    }
});

var uploadBtn = document.getElementById("Block_Change_Profile_Picture_Block_Block_Change_Profile_Picture_SN_EditProfilePictureViaForm_Submit");
if (uploadBtn) {
    uploadBtn.value = "Opslaan";
}